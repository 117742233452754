<template>
  <InputText v-model="localLocation" type="text" class="input-text" @input="handleInputChange" placeholder="Location"
    @blur="onBlurLocation(localLocation)" />
  <div v-if="locationErrorMessage" class="error-message-div">
    <p>{{ locationErrorMessage }}</p>
  </div>
</template>

<script setup>

import { defineEmits, defineProps, ref, watch } from 'vue'
import InputText from 'primevue/inputtext'

const props = defineProps({
  locationErrorMessage: {
    type: String,
    default: '',
  },
  tumorBoardLocation: {
    type: String,
    default: '',
  },
})

const emit = defineEmits(['update-location-error-message', 'update-tumor-board-location'])

const localLocation = ref(props.tumorBoardLocation)

const handleInputChange = (event) => {
  localLocation.value = event.target.value
  checkForError(event.target.value)
}

const onBlurLocation = (loc) => {
  emit('update-tumor-board-location', loc)
}
const validateLocation = (location) => {
  if (location?.trim().length > 100) { return 'Input cannot exceed 100 characters' }
  return ''
}

const checkForError = (loc) => {
  const newErrorMessage = validateLocation(loc)
  emit('update-location-error-message', newErrorMessage)
}

watch(localLocation, (newValue) => {
  emit('update-tumor-board-location', newValue)
})

watch(() => props.tumorBoardLocation, (newVal) => {
  localLocation.value = newVal
})
</script>

<style lang="scss" scoped></style>
