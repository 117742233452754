<template>
  <div class="field-margin">
    <span class="label-span">{{ `Day & Time (${timeZone || ''})` }}</span>
  </div>
  <div class="meeting-div">
    <Calendar class="start-date" v-model="startDate" :minDate="minDate" :manualInput="false" dateFormat="dd-M-yy"
      @blur="updateTimeAndDate" />
    <Calendar v-model="startTime" :timeOnly="true" hourFormat="12" @blur="updateTimeAndDate" />
    <div class="mr-2 ml-2">
      <span>to</span>
    </div>
    <div>
      <Calendar v-model="endTime" :timeOnly="true" hourFormat="12" @blur="updateTimeAndDate" />
    </div>
    <div v-if="duration.length" class="how-long-mtg">
      <div class="ml-2">
      </div>
      <div class="duration-container">
        <span class="material-symbols-outlined clock-icon" data-v-6b1f8bd0="">
          schedule
        </span>
        <span>{{ duration }}</span>
      </div>
    </div>
    <div v-else class="error-message">
      <span>End time must be greater than start time</span>
    </div>
  </div>
</template>

<script setup>
import moment from 'moment'
import Calendar from 'primevue/calendar'
import { defineEmits, defineProps, onMounted, ref, watch } from 'vue'
import { convertToDateTime } from '@/utils/utils.js'

const props = defineProps({
  timeAndDateObj: {
    type: Object,
    default: () => ({}),
  },
  timeZone: {
    type: String,
    default: '',
  },
  frequency: {
    type: Object,
    default: () => ({ id: 'DO NOT REPEAT', name: 'Do Not Repeat' }),
  },
})

const emit = defineEmits(['date-time-update'])

const startDate = ref('')
const endTime = ref('')
const startTime = ref('')
const duration = ref('')
const minDate = ref(null)
const isRecurranceActive = ref('')

const extractTime = (input) => {
  const inputStr = input?.toString()

  if (inputStr && inputStr.includes('GMT')) {
    const momentDate = moment(new Date(inputStr))

    return momentDate.format('HH:mm')
  } else if (inputStr && inputStr.length <= 5 && inputStr.includes(':')) {
    return inputStr
  }

  return '00:00'
}

const timeToMinutes = (time) => {
  const [hrs, mins] = time.split(':').map(Number)
  return hrs * 60 + mins
}

const diffHours = (start, end) => {
  const startTime = extractTime(start)
  const endTime = extractTime(end)

  const startMinutes = timeToMinutes(startTime)
  const endMinutes = timeToMinutes(endTime)
  const diffInMin = endMinutes - startMinutes
  const hours = Math.floor(diffInMin / 60)
  const minutes = diffInMin % 60

  let formattedDuration = ''
  if (hours > 0) {
    formattedDuration += `${hours} hour${hours !== 1 ? 's' : ''}`
  }
  if (minutes > 0) {
    if (formattedDuration.length > 0) {
      formattedDuration += ' and '
    }
    formattedDuration += `${minutes} minute${minutes !== 1 ? 's' : ''}`
  }

  duration.value = formattedDuration
}

const updateTimeAndDate = () => {
  const timeAndDateObj = {
    startDate: startDate.value,
    startTime: extractTime(startTime.value),
    endTime: extractTime(endTime.value),
    duration: duration.value,
  }

  emit('date-time-update', timeAndDateObj)
}

watch(startTime, (newStartDateTime) => {
  if (!newStartDateTime) { return }
  diffHours(newStartDateTime, endTime.value)
})

watch(endTime, (newEndDateTime) => {
  if (!newEndDateTime) { return }
  diffHours(startTime.value, newEndDateTime)
})

watch(() => props.timeAndDateObj, (newVal) => {
  if (!newVal) { return }
  const { frequency, start_date: newStartDate, start_time: newStartTime, end_time: newEndTime } = newVal.tb_occurrence

  startDate.value = newStartDate
  if (newStartTime) {
    startTime.value = convertToDateTime(startDate.value, newStartTime)
  }

  if (newEndTime) {
    endTime.value = convertToDateTime(startDate.value, newEndTime)
  }

  if (frequency === 'DO NOT REPEAT') {
    isRecurranceActive.value = false
    minDate.value = new Date()
  } else {
    isRecurranceActive.value = true
    minDate.value = null
  }
}, { deep: true })

onMounted(() => {
  const { start_date: newStartDate, start_time: newStartTime, end_time: newEndTime } = props.timeAndDateObj.tb_occurrence

  startDate.value = newStartDate
  // The time values need to be converted as they are passed into this component
  // because PrimeVue Calendar component needs a specific date string format. They
  // are then converted back to the original format when they are passed back to the
  // parent.
  startTime.value = convertToDateTime(newStartDate, newStartTime)
  endTime.value = convertToDateTime(newStartDate, newEndTime)
  diffHours(startTime.value, endTime.value)
})
</script>

<style lang="scss" scoped>
.start-date {
  margin-right: 0.5rem;
}

.field-margin {
  margin-top: 2%;
  margin-bottom: 1%;
}

.meeting-div {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.how-long-mtg {
  display: flex;
  align-items: center;
  margin: 0 !important;
  padding-right: 5px;
}

.duration-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--Grey300);
  padding: 7px 10px;
  border-radius: 5px;
  font-size: 0.9rem;
  margin-left: 5px;

  .clock-icon {
    margin-right: 4px;
  }
}

.error-message {
  color: var(--ErrorRedDark);
  margin-left: 0.5rem;
}
</style>
