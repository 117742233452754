<template>
  <div>
    <div class="drawer" :class="{ 'is-open': isOpen, 'is-visible': isVisible }">
      <div class="drawer__overlay" :style="{ transitionDuration: `${props.speed}ms` }" @click.prevent="onClose"></div>
      <div class="drawer__content" :style="{ maxWidth, transitionDuration: `${speed}ms`, backgroundColor, }">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineEmits, defineProps, onMounted, ref, watch } from 'vue'

const props = defineProps({
  isOpen: {
    type: Boolean,
    required: false,
    default: false,
  },

  maxWidth: {
    type: String,
    required: false,
    default: '1000px',
  },

  // Transition Speed in Milliseconds
  speed: {
    type: Number,
    required: false,
    default: 300,
  },

  backgroundColor: {
    type: String,
    required: false,
    default: '#fafafa',
  },
})

const emit = defineEmits(['close'])

const isVisible = ref(false)
const isTransitioning = ref(false)

const isOpen = computed(() => props.isOpen)
watch(isOpen, (val) => {
  isTransitioning.value = true

  if (val) {
    isVisible.value = true
    // toggleBackgroundScrolling(true)
  } else {
    // toggleBackgroundScrolling(false)
    setTimeout(() => (isVisible.value = false), props.speed)
  }

  setTimeout(() => (isTransitioning.value = false), props.speed)
})

// const toggleBackgroundScrolling = (enable) => {
//   const body = document.querySelector("body")

//   body.style.overflow = enable ? "hidden" : null
// }

const onClose = () => {
  if (!isTransitioning.value) { emit('close') }
}

onMounted(() => isVisible.value = props.isOpen)
</script>

<style lang="scss" scoped>
.drawer {
  visibility: hidden;

  &.is-visible {
    visibility: visible;
  }

  &.is-open {
    .drawer__overlay {
      opacity: 0;
    }

    .drawer__content {
      transform: translateX(0);
    }
  }

  &__overlay {
    position: fixed;
    top: 71px;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 90;
    opacity: 0;
    transition-property: opacity;
    background-color: #000000;
    user-select: none;
  }

  &__content {
    position: fixed;
    top: 71px;
    left: 2.5rem;
    bottom: 0;
    height: calc(100vh - 70px);
    width: 100%;
    z-index: 90;
    overflow: auto;
    transition-property: transform;
    display: flex;
    flex-direction: column;
    transform: translateX(-100%);
    box-shadow: 0 2px 6px #777;
  }
}
</style>
