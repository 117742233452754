<template>
  <div>
    <BaseCard :card-name="tr('Tumor Board Sessions')" :spinnerOn="filingSpinner">
      <div class="base-tb-container">
        <div class="tab-container">
          <div class="tab" :class="{ 'tab-active': tabPreviousActive }" id="tb-all-tab" @click="tabTBClick('Previous')">
            {{ tr('Previous Sessions') }}
          </div>
          <div class="tab" :class="{ 'tab-active': tabUpcomingActive }" id="tb-gen-tab" @click="tabTBClick('Upcoming')">
            {{ tr(dynamicTabHeading) }}</div>
        </div>
        <div class="tb-list-container">
          <div class="tb-container" :class="{ 'tb-container-active': tabPreviousActive }" id="tb-all-list">
            <TumorBoardPreviousSessions />
          </div>
          <div v-if="startRecordSession && isClickedCurrentSession" class="tb-container"
            :class="{ 'tb-container-active': tabUpcomingActive }" id="tb-gen-list">
            <TumorBoardCurrentSession />
          </div>
          <div v-else class="tb-container" :class="{ 'tb-container-active': tabUpcomingActive }" id="tb-gen-list">
            <TumorBoardUpcomingSessions />
          </div>
        </div>
      </div>
    </BaseCard>
  </div>
</template>
<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import BaseCard from '../BaseCard/BaseCard.vue'
import TumorBoardPreviousSessions from '../TumorBoardSessions/TumorBoardPreviousSessions.vue'
import TumorBoardUpcomingSessions from '../TumorBoardSessions/TumorBoardUpcomingSession.vue'
import TumorBoardCurrentSession from '../TumorBoardSessions/TumorBoardCurrentSession.vue'
import { useStore } from 'vuex'

const store = useStore()
const tr = (x) => x // inject('tr')
const tabPreviousActive = ref(true)
const tabUpcomingActive = ref(false)
const dynamicTabHeading = ref('Upcoming Session')
const patient = computed(() => store.getters.session.patient)
const tumorBoardSessionsData = computed(() => store.getters.session.tumorBoardSessionsData)
const startRecordSession = computed(() => store.state.session.isStartRecordSession)
const isClickedCurrentSession = computed(() => store.state.session.isClickedCurrentSession)

watch(tumorBoardSessionsData, async (newTumorBoardSessionsData) => {
  if (!newTumorBoardSessionsData) { return }
  const upcomingSessionData = newTumorBoardSessionsData.Upcoming
  const pendingSessionData = newTumorBoardSessionsData.Pending
  const previousSessionsData = newTumorBoardSessionsData.Past
  dynamicTabHeading.value = startRecordSession.value && isClickedCurrentSession.value ? 'Current Session' : (upcomingSessionData?.length || pendingSessionData?.length) && patient ? 'Upcoming Session' : 'New Session'
  tabPreviousActive.value = !!previousSessionsData?.length
  tabUpcomingActive.value = !tabPreviousActive?.value
  if (dynamicTabHeading.value === 'Current Session') {
    tabUpcomingActive.value = true
    tabPreviousActive.value = false
  }
})

const clearTBTabs = () => {
  tabPreviousActive.value = false
  tabUpcomingActive.value = false
}

const tabLookup = { 'Previous': tabPreviousActive, 'Upcoming': tabUpcomingActive }

const tabTBClick = (tab) => {
  clearTBTabs()
  tabLookup[tab].value = true
}

//const patientId = '0x444531'  // TODO

const filingSpinner = ref(false)

onMounted(async () => {
  // TODO: pull TB data for patient
})
</script>

<style lang="scss" scoped>
.base-tb-container {
  height: calc(68vh - 165px);
  overflow-y: auto;
  transition: opacity linear 1000ms;
}

.tb-container {
  display: none;
  padding: 15px;
}

.tb-container div {
  width: 100%;
}

.tb-container-active {
  display: flex;
}
</style>

